.register {
	@include spacing(8rem);

	background-color: #47505d;
	font-family: 'Cera Pro', Roboto, Droid Sans;

	h2 {
		@extend %heading2;

		span {
			color: #ffbc36;
		}
	}

	.btn {
		@extend %button;
		box-shadow: 0px 4px 33px rgba(0, 0, 0, 0.22);
		color: white !important;
		margin: 4rem auto;

		&,
		&:focus,
		&:active {
			background-color: #1a2639 !important;
		}
	}

	.info {
		display: flex;
		justify-content: space-around;
		flex-wrap: wrap;
		color: #fff;
		font-size: 1.8rem;
		text-align: left;
		margin-top: 1.5rem;

		div {
			display: flex;
			margin: 1.5rem 0;
			min-width: 30rem;
			justify-content: left;
			align-items: center;

			svg {
				margin: 0 1rem;
				width: 3rem;
			}
		}

		@media (max-width: 700px) {
			font-size: 1.6rem;
			div {
				svg {
					width: 2rem;
				}
			}
		}
		@media (max-width: 400px) {
			font-size: 1.4rem;
			div {
				min-width: 25rem;
				svg {
					width: 1.8rem;
				}
			}
		}
	}
}
