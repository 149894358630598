.darkButton {
	height: 50px;
	display: flex;
	border-radius: 4px;
	justify-content: center;
	align-items: center;
	font-size: 14px;
	cursor: pointer;
	margin: 1rem 0;
	background: #1a2639;
	color: white;
}
