.subscribe {
	@include spacing(8rem);

	font-family: 'Cera Pro', Roboto, Droid Sans;

	h2 {
		@extend %heading2;
	}
	p {
		@extend %subHeading;
		margin: 3rem 0;
	}
	.emailBox {
		border-radius: 25px;
		margin: 2rem auto;
		max-width: 50rem;

		input {
			&,
			&:focus,
			&:checked {
				// border: none;
				box-shadow: none;
				outline: none;
			}
			&::placeholder {
				color: #d3d3d3;
			}
			padding: 1rem 1.5rem;
			font-family: 'Cera Pro', Roboto, Droid Sans;
			background-color: transparent;
			border-bottom-left-radius: 25px;
			border-top-left-radius: 25px;
			border: 2px solid #ffffff;
			font-size: 1.8rem;
			font-weight: 500;
			color: #fff;
			height: fit-content;
		}

		.input-group-append {
			.input-group-text {
				font-size: 1.6rem;
				font-weight: 600;
				border: none;
				border-bottom-right-radius: 25px;
				border-top-right-radius: 25px;
				background-color: #fff;
				padding: 1rem 1.5rem;
			}
		}
		.subscribeBtn {
			cursor: pointer;
		}
	}

	%message {
		width: fit-content;
		text-align: center;
		border-radius: 4px;
		margin: 1rem auto;
		padding: 0.5rem 3rem;
		font-size: 1.6rem;
		color: #e1e1e1;
	}

	.error-message {
		@extend %message;
		background-color: rgba(239, 103, 100, 0.6);
	}
	.success-message {
		@extend %message;
		background-color: rgba(129, 199, 132, 0.6);
	}

	.loader {
		width: fit-content;
		margin: 2rem auto 0 auto;
	}
}
