.launchingSoon {
	@include spacing(8rem);
	padding-bottom: 0 !important;
	position: relative;
	font-family: 'Cera Pro', Roboto, Droid Sans;
	text-align: center;
	overflow-x: hidden;

	h2 {
		@extend %heading2;
		font-weight: 500;

		.first {
			display: inline;
			font-size: calc(1.2em);
		}

		// @media (max-width: 1200px) {
		// 	font-size: 4.5rem;
		// }
		// @media (max-width: 990px) {
		// 	font-size: 4rem;
		// }

		@media (max-width: 700px) {
			font-size: 3.5rem;
		}
		@media (max-width: 500px) {
			font-size: 3rem;
		}
		@media (max-width: 400px) {
			font-size: 2.5rem;
		}
		@media (max-width: 350px) {
			font-size: 2rem;
		}

		.soon {
			display: block;
			color: #ffbc36;
			font-size: 8rem;
			font-weight: 600;
			margin: 4rem 0;

			@media (max-width: 1200px) {
				font-size: 6rem;
			}
			@media (max-width: 990px) {
				font-size: 5.5rem;
			}

			@media (max-width: 700px) {
				font-size: 5rem;
			}
			@media (max-width: 500px) {
				font-size: 4.5rem;
			}
			@media (max-width: 400px) {
				font-size: 3.5rem;
			}
		}
	}

	svg {
		max-width: 70rem;
	}

	.ellipse {
		position: absolute;
		display: block;
		top: 0;
		left: -5rem;
		right: -5rem;
		height: 50rem;
		background-color: #47505d;
		border-radius: 50% / 100%;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		z-index: -1;

		@media (max-width: 1500px) {
			height: 45rem;
		}
		@media (max-width: 1200px) {
			height: 40rem;
			left: -15rem;
			right: -15rem;
		}
		@media (max-width: 700px) {
			height: 38rem;
		}
		@media (max-width: 500px) {
			height: 35rem;
		}
		@media (max-width: 400px) {
			height: 30rem;
		}
	}
}
