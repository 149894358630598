.navbar {
	background-color: #47505d;
	color: #bef1ff;
	font-family: 'Cera Pro', Roboto, Droid Sans;
	font-weight: 500;
	font-size: 2rem;

	.navbar-brand {
		// font-size: 2rem;
		cursor: pointer;

		svg {
			width: 6rem;
			margin-left: 1rem;

			@media (max-width: 700px) {
				width: 5rem;
			}
			@media (max-width: 450px) {
				width: 4rem;
			}
		}
	}

	.navbar-toggler {
		svg {
			width: 2rem;
		}
		&,
		&:focus {
			border: none;
			outline: none;
		}
		font-size: 2rem;
	}

	.navbar-nav {
		align-items: center;
		padding: 1rem 0;

		.nav-link {
			// &:active,
			&:focus {
				color: #fff;
			}
			&:hover {
				color: #ffbc36;
			}
			&.active {
				color: #ffbc36;
			}

			color: #fff;
			padding: 0.5rem 2rem;
		}

		.btn {
			@extend %button;
			margin: 0;
			@media (max-width: 990px) {
				margin: 2rem 0;
			}
		}
	}
}
