.aboutUs {
	@include spacing(8rem);

	color: #fff;
	font-family: 'Cera Pro', Roboto, Droid Sans;
	text-align: center;

	h2 {
		@extend %heading;
	}

	p {
		@extend %subHeading;
		width: fit-content;
		color: #fff;
		text-align: center;
		font-style: italic;
		margin: 6rem auto 0 auto;
		font-weight: 600;
		// padding: 0 2rem;
		font-size: 4rem;

		&.subLine {
			font-style: normal;
			margin: 2rem auto;
		}

		@media (max-width: 1200px) {
			font-size: 3.7rem;
		}
		@media (max-width: 990px) {
			font-size: 3.3rem;
		}

		@media (max-width: 700px) {
			font-size: 3rem;
		}
		@media (max-width: 400px) {
			font-size: 2.8rem;
		}

		.pre-text {
			position: relative;
			// max-width: 15rem;
			// display: inline-block;

			&::before {
				content: url('../../icons/quote.svg');
				position: inherit;
				display: inline-block;
				top: 1.5rem;
				left: 4rem;
				width: 10rem;
				height: 10rem;
				margin-left: -4rem;

				@media (max-width: 700px) {
					width: 8rem;
					height: 8rem;
					left: 3rem;
				}
			}
		}
	}

	.message {
		z-index: 2;
		display: flex;
		max-width: 130rem;
		margin: 12rem auto;
		border: 10px solid #ffbc36;
		align-items: center;

		.text {
			order: 0;
			font-weight: 500;
			font-size: 3.5rem;
			text-align: left;
			padding: 0 10rem 0 5rem;

			.btn {
				@extend %button;
			}
		}

		img {
			order: 1;
			z-index: -1;
			transform: scale(1.2);
			width: 50rem;
		}

		@media (max-width: 1200px) {
			.text {
				font-size: 2.8rem;
				padding: 0 6rem 0 3rem;
			}

			img {
				width: 45rem;
			}
		}

		@media (max-width: 990px) {
			.text {
				font-size: 2.4rem;
			}

			img {
				width: 35rem;
			}
		}
		@media (max-width: 860px) {
			flex-direction: column;
			width: auto;
			padding: 2rem;
			margin: 4rem auto;
			.text {
				order: 1;
				// font-size: 2.2rem;
				padding: 0;
				padding-top: 2rem;
			}

			img {
				order: 0;
				z-index: 0;
				width: 100%;
				object-fit: cover;
				transform: none;
			}
		}

		@media (max-width: 550px) {
			border: 5px solid #ffbc36;
		}
	}
}
