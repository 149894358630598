.faq {
	@include spacing(8rem);

	color: #fff;
	font-family: 'Cera Pro', Roboto, Droid Sans;
	text-align: center;
	background-color: #47505d;

	h2 {
		@extend %heading2;
	}

	.accordion {
		margin: 4rem auto 0 auto;
		// max-width: 90%;

		.card {
			background: #1a2639;
			box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.02),
				0px 4px 5px rgba(0, 0, 0, 0.05);
			border-radius: 10px;
			margin: 1rem 0;
			border: none;

			.card-header {
				cursor: pointer;
				background: #1a2639;
				border: none;
				// font-size: 20px;
				padding: 0;
				text-align: left;
				div {
					padding: 1.5rem 2rem;
					display: flex;
					align-items: center;
					justify-content: space-between;
					font-size: 2rem;

					svg {
						flex-shrink: 0;
						margin-left: 2rem;
						width: 1.6rem;
					}
				}
			}
			.card-body {
				text-align: left;
				padding: 0 2rem 1.5rem 2rem;
				font-size: 1.6rem;
			}

			// @media (max-width: 1200px) {
			// 	font-size: 3rem;
			// }
			// @media (max-width: 990px) {
			// 	font-size: 2.8rem;
			// }

			@media (max-width: 700px) {
				.card-header {
					div {
						font-size: 1.8rem;
					}
				}
				.card-body {
					font-size: 1.5rem;
				}
			}
			@media (max-width: 450px) {
				.card-header {
					div {
						font-size: 1.6rem;
					}
				}
				.card-body {
					font-size: 1.4rem;
				}
			}
			@media (max-width: 400px) {
				.card-header {
					div {
						font-size: 1.5rem;
					}
				}
				.card-body {
					font-size: 1.4rem;
				}
			}
		}
	}
}
