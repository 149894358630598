.blogBox {
	display: flex;
	flex-wrap: nowrap;
	padding: 2rem 0;
	// justify-content: space-between;
	height: 35rem;

	.imageBox {
		flex-basis: 30%;
		img {
			width: 100%;
			// height: 20vw;
			height: 100%;
			object-fit: cover;
		}
	}

	.blogInfo {
		flex-basis: 70%;
		margin-left: 3rem;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		h1 {
			@extend %subHeading;
			// margin: 3rem 0;
			text-align: left;
			margin-bottom: 2rem;
		}

		p {
			font-family: Cera Pro;
			font-style: normal;
			// font-weight: 500;
			font-size: 20px;
			// line-height: 25px;

			color: #ffffff;
			display: -webkit-box;
			-webkit-line-clamp: 5;
			-webkit-box-orient: vertical;
			overflow: hidden;
			text-overflow: ellipsis;
			// margin: 2rem 0;

			// @media (max-width: 1700px) {
			// 	-webkit-line-clamp: 5;
			// }
		}

		.btn {
			&:hover {
				background-color: #ffbc36 !important;
			}

			@extend %button;
			width: fit-content;
			background-color: #ffffff !important;
			font-size: 1.8rem;
			padding: 1rem 4rem;
			margin: 0;
			margin-top: 2rem;
		}
	}

	@media (max-width: 700px) {
		flex-direction: column;
		height: unset;

		.imageBox {
			img {
				width: 100%;
				height: 30rem;
			}
		}

		.blogInfo {
			margin-left: 0;

			h1 {
				margin: 2rem 0;
			}

			p {
				-webkit-line-clamp: 6 !important;
			}
		}
	}
	@media (max-width: 500px) {
		.blogInfo {
			h1 {
				font-size: 2.2rem;
			}

			p {
				font-size: 1.8rem;
			}
		}
	}
	@media (max-width: 350px) {
		.imageBox {
			img {
				width: 100%;
				height: 25rem;
			}
		}
		.blogInfo {
			h1 {
				font-size: 2rem;
			}

			p {
				font-size: 1.6rem;
			}
		}
	}
}
