@mixin spacing($top-bottom-spacing) {
	padding: $top-bottom-spacing 5rem;

	@media (max-width: 700px) {
		padding: $top-bottom-spacing 3rem;
	}
	@media (max-width: 380px) {
		padding: $top-bottom-spacing 2rem;
	}
	@media (max-width: 350px) {
		padding: $top-bottom-spacing 1rem;
	}
}
