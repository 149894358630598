.process {
	@include spacing(8rem);

	background-color: #47505d;
	font-family: 'Cera Pro', Roboto, Droid Sans;
	color: #fff;
	text-align: center;

	h2 {
		@extend %heading;
		font-weight: 500;
		margin-bottom: 8rem;

		@media (max-width: 700px) {
			font-size: 4.5rem;
		}
		@media (max-width: 500px) {
			font-size: 3.5rem;
		}
		@media (max-width: 350px) {
			font-size: 3rem;
		}
	}
	svg {
		display: block;
		max-width: 120rem;
		margin: 0 auto;

		@media (max-width: 500px) {
			max-width: 35rem;
		}
	}
}
