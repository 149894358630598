%heading {
	font-weight: normal;
	position: relative;
	display: inline-block;
	// padding-bottom: 0.5rem;
	font-size: 5.4rem;

	&:after {
		content: '';
		position: absolute;
		bottom: -1rem;
		left: 33%;
		height: 0;
		width: 33%;
		border-bottom: 2px solid #ffbc36;
	}

	@media (max-width: 450px) {
		font-size: 4.5rem;
	}
	@media (max-width: 350px) {
		font-size: 4rem;
	}
}

%subHeading {
	text-align: center;
	font-weight: 500;
	font-size: 3.4rem;
	color: #ffbc36;

	@media (max-width: 1200px) {
		font-size: 3rem;
	}
	@media (max-width: 990px) {
		font-size: 2.8rem;
	}

	@media (max-width: 700px) {
		font-size: 2.5rem;
	}
	@media (max-width: 400px) {
		font-size: 2.3rem;
	}
}

%heading2 {
	// max-width: 90%;
	font-weight: 600;
	font-size: 5rem;
	text-align: center;
	color: #fff;
	// margin: 3rem auto;
	@media (max-width: 1200px) {
		font-size: 4.5rem;
	}
	@media (max-width: 990px) {
		font-size: 4rem;
	}

	@media (max-width: 700px) {
		font-size: 3.5rem;
	}
	@media (max-width: 400px) {
		font-size: 3rem;
	}
}
