.chooseCategory {
	// margin-top: 3rem;
	font-family: 'Cera Pro', Roboto, Droid Sans;
	display: flex;
	justify-content: center;
	// align-items: center;
	// height: 95vh;
	flex-direction: column;

	h1 {
		@extend %subHeading;
		text-align: center;
		text-transform: uppercase;
		// font-size: 20px;
		font-weight: bold;
		margin-top: 4rem;
		color: rgb(51, 34, 71);
	}

	.categoryContainer {
		max-height: fit-content;
		width: 90%;
		padding: 0 1rem;
		max-width: 480px;
		margin: 2rem auto;

		.row {
			display: flex;
			flex-basis: 50%;
			.col {
				cursor: pointer;
				padding: 1.5rem 0;
				border-bottom: 1px solid #d2d2d2;
				min-width: 10rem;

				@media (max-width: 230px) {
					border: none !important;
				}

				svg {
					display: block;
					height: 60px;
					width: 60px;

					margin: 0 auto;
				}

				p {
					font-size: 14px;
					margin-top: 1rem;
					font-family: font(secondary);
					text-align: center;
					text-transform: uppercase;
					font-weight: 500;
				}
			}
			.col-l {
				border-right: 1px solid #d2d2d2;
			}
		}
	}

	.verification {
		font-size: 1.6rem;
		margin: 0 auto 3rem auto;
		padding: 0 1rem;
		li {
			.heading {
				font-weight: 600;
				text-transform: uppercase;
			}
		}
		h4 {
			text-transform: uppercase;
			color: #1a2639;
			font-weight: 600;
			// text-align: center;
			font-size: 1.8rem;
			margin-bottom: 1rem;
			border-bottom: 1px solid #1a2639;
			display: inline-block;
		}
	}
}
