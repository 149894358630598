.footer {
	font-family: 'Cera Pro', Roboto, Droid Sans;
	font-weight: 500;
	color: white;
	// margin: 2rem 0;
	background: #2f3a4b;
	@include spacing(2rem);

	.info {
		@media (max-width: 1200px) {
			flex-direction: column;
			justify-content: center;
			align-items: center;
			text-align: center;
		}

		display: flex;
		align-items: flex-start;
		justify-content: space-around;
		margin: 2rem auto;
		flex-wrap: wrap;
		// max-width: 90%;
		align-items: flex-start;

		.logo {
			margin: 2rem 0;
			cursor: pointer;

			svg {
				width: 12rem;
			}
		}

		.links {
			div:first-of-type {
				color: #7b7b7b;
				margin-bottom: 0.25rem;
				font-size: 2rem;
				font-weight: 500;
				cursor: auto;
			}
			line-height: 3rem;
			font-weight: 400;
			display: flex;
			flex-direction: column;
			margin: 2rem;
			font-size: 1.8rem;
			cursor: pointer;
		}
		.social {
			align-self: center;
			display: flex;
			flex-direction: column;
			margin: 1rem 0;

			.row {
				display: flex;
				justify-content: center;
				flex-shrink: 0;
				svg {
					width: 4.5rem;
					cursor: pointer;
					margin: 1rem;
				}
			}
		}
	}

	// .navigation {
	// 	display: flex;
	// 	justify-content: center;
	// 	align-items: center;
	// 	flex-wrap: wrap;

	// 	div {
	// 		font-size: 14px;
	// 		margin: 00.25rem;
	// 	}
	// }
}
.modal {
	.modal-header {
		.close {
			font-size: 3rem;
		}
		.modal-title {
			font-size: 2.5rem;
		}
	}

	.modal-body {
		padding: 2rem;
		h1 {
			font-size: 2rem;
		}
		ol {
			margin: 1rem 0;
		}
	}

	.modal-footer {
		.btn {
			@extend %button;
			margin: 0.5rem;
		}
	}

	font-size: 1.5rem;
}
