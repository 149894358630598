.banner {
	@include spacing(20rem);

	color: white;
	position: relative;
	display: flex;
	align-items: center;

	@media (max-width: 1400px) {
		padding: 15rem 5rem;
	}
	@media (max-width: 1200px) {
		padding: 12rem 5rem;
	}
	@media (max-width: 990px) {
		padding: 10rem 5rem;
	}
	@media (max-width: 850px) {
		padding: 6rem 5rem;
	}
	@media (max-width: 700px) {
		padding: 8rem 5rem;
	}
	@media (max-width: 400px) {
		padding: 4rem 3rem;
	}
	@media (max-width: 350px) {
		padding: 3rem;
	}

	.title {
		color: #bfe1ff;
		line-height: 7rem;
		z-index: 2;
		max-width: 75rem;
		font-family: 'Cera Pro', Roboto, Droid Sans;
		font-size: 5rem;
		font-weight: 600;

		span {
			display: block;
			// color: #fff;
			animation-name: whiter;
			animation-duration: 1s;
			animation-timing-function: ease-in-out;
			animation-delay: 1s;
			animation-iteration-count: 1;
			animation-direction: normal;
			animation-fill-mode: forwards;
			animation-play-state: running;
		}

		@keyframes whiter {
			0% {
				// color: #bfe1ff;
			}
			100% {
				color: #fff;
			}
		}
		@media (max-width: 1400px) {
			max-width: 55rem;
		}

		@media (max-width: 1200px) {
			font-size: 4rem;
			line-height: 6rem;
			max-width: 45rem;
		}
		@media (max-width: 990px) {
			font-size: 3.5rem;
			line-height: 5rem;
			max-width: 35rem;
		}
		@media (max-width: 850px) {
			max-width: 35rem;
		}

		@media (max-width: 700px) {
			max-width: 100%;
			font-size: 4rem;
		}
		@media (max-width: 400px) {
			font-size: 3.5rem;
		}
		@media (max-width: 350px) {
			font-size: 3rem;
		}
	}

	.barter {
		z-index: 1;
		position: absolute;
		// bottom: 20rem;
		right: 2rem;
		width: 65rem;

		@media (max-width: 1400px) {
			width: 60rem;
		}

		@media (max-width: 1200px) {
			width: 50rem;
			// bottom: 18rem;
		}

		@media (max-width: 990px) {
			width: 50rem;
			// bottom: 20rem;
		}
		@media (max-width: 850px) {
			width: 40rem;
		}
		@media (max-width: 700px) {
			display: none;
		}
	}

	.bg {
		// z-index: -1;
		position: absolute;
		right: 0;
		height: 85rem;
		@media (max-width: 1400px) {
			height: 80rem;
		}

		@media (max-width: 1200px) {
			height: 68rem;
		}

		@media (max-width: 990px) {
			height: 65rem;
		}
		@media (max-width: 850px) {
			height: 50rem;
		}

		@media (max-width: 700px) {
			display: none;
		}
	}

	.btn {
		@extend %button;
	}
}
