@import './blogBox', './blogPost';

.blog {
	@include spacing(4rem);
	background-color: #1a2639;

	@media (min-width: 1200px) {
		min-height: calc(100vh - 7.3rem - 27.3rem);
	}

	// h1.title {
	// 	@extend %heading;
	// 	color: white;
	// 	display: block;
	// 	text-align: center;
	// 	margin: 2rem 0;
	// }

	h4 {
		font-family: Cera Pro;
		font-style: normal;
		font-weight: 500;
		font-size: 20px;
		line-height: 25px;
		text-transform: uppercase;
		color: #ffbc36;
		border-bottom: 1px solid #fff;
		padding-bottom: 2rem;
	}
}
